body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

.containter {
  margin: auto;
  color: rgba(0, 0, 0, 0.9);
  font-family: Georgia, serif;
  font-size: 1.25rem;
  p{
    line-height: 1.6rem;
  }
  width: 800px;

  &.title {
    text-align: center;
  }

  .authors {
    display: flex; 
    justify-content: center;

    div{
      margin: 0 2rem;
      h3{
        margin: 0.5rem 0;
      }
    }

  }

  img{
    width: 100%;
  }

  .figure {
    text-align: center;
  }

  .caption {
    color: rgba(0, 0, 0, 0.7);
    font-size: 1rem;
    line-height: 1.5em;
    margin-top: 0;
  }
}

@media only screen and (max-width: 800px) {
  .containter {
    width: 100%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;

  .gohome {
    position: absolute;
    top:2px;
    left:8px;
    color: white;
    z-index: 10;
  }

  .videoContainer {
    width: 50%;
    position: relative;

    p{
      margin-right: 0.5rem;
    }

    .videoControl{
      margin-top: 2px;
      color: white;
      position: absolute;
      top:0;
      right:0;
      display: flex;
      align-items: center;
      z-index: 2;
      text-align: center;
    }

    video {
      position: absolute;
      top:0;
      left:0;
      max-height: 100vh;
    }
    video:nth-child(1){
      position: relative;

    }
  }

  .webViz {
    position: relative;
    width: 100%;

    .controlBoard {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-wrap: wrap;
      z-index: 999;
      background-color: #000000c7;
      color: white;

      span{
        margin-right: 4px;
      }

      .gv {
        height: 25px;
        width: 25px;
        background-color:  #bf99bf;
        border-radius: 50%;
        display: inline-block;
      }
      .gw {
        height: 17px;
        width: 17px;
        background-color: #bf99bf;
        border-radius: 50%;
        display: inline-block;
      }
      .dv {
        height: 25px;
        width: 25px;
        background-color: #00cc00;
        border-radius: 50%;
        display: inline-block;
      }
      .dw {
        height: 17px;
        width: 17px;
        background-color: #ff7f00;
        border-radius: 50%;
        display: inline-block;
      }
    }

    .worldView {
      height: 100vh;
      cursor: move; /* fallback if grab cursor is unsupported */
      cursor: grab;
      cursor: -moz-grab;
      cursor: -webkit-grab;

      &:active {
        cursor: grabbing;
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
      }
    }
  }

  button {
    height: 3rem;
  }

  h1,
  p {
    margin: 0;
  }
  h1 {
    margin-bottom: 1rem;
  }
}
